<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Aufgabenbereich bearbeiten"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
        class="col s8"
      >
        <assignment-editor v-model="assignment" mode="edit"></assignment-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import AssignmentEditor from "@/components/member-management/assignments/AssignmentEditor.vue";
import { MEMBER_MANAGEMENT_ASSIGNMENTS_update } from "@/data/permission-types.js";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_ASSIGNMENT } from "@/store/action-types.js";

export default {
  name: "member-management-assignments-assignment-edit",
  components: {
    NavigationBar,
    AssignmentEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      assignment: null,
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    memberships() {
      var result = this.$store.state.admin.membershipsPublic.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
    assigneeUids() {
      return this.assignment.assignees.map((item) => {
        return item.user.uid;
      });
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("assignments")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.assignment = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        const hasDuplicates = (array) => new Set(array).size !== array.length;

        if (hasDuplicates(this.assigneeUids)) {
          alert(
            "Fehler: Es dürfen keine doppelten Mitglieder zugeordnet werden."
          );
        } else {
          this.$store
            .dispatch(`${MEMBER_MANAGEMENT}${UPDATE_ASSIGNMENT}`, {
              organizationId: this.$route.params.organizationId,
              itemId: this.$route.params.itemId,

              title: this.assignment.title,
              description: this.assignment.description,
              isHidden: this.assignment.isHidden,
              contact: {
                emails: this.assignment.contact.emails,
                links: this.assignment.contact.links,
              },
              color: this.assignment.color,
              icon: this.assignment.icon,
              priorityScore: this.assignment.priorityScore,
              workloadScore: this.assignment.workloadScore,
              assignees: this.assignment.assignees,
              analysis: {
                minAssignees: this.assignment.analysis.minAssignees,
                maxAssignees: this.assignment.analysis.maxAssignees,
                currentAssignees: this.assignment.analysis.currentAssignees,
                currentLeaders: this.assignment.analysis.currentLeaders,
                isShown: this.assignment.analysis.isShown,
              },
            })
            .catch((error) => {
              alert(
                "Es ist ein Fehler aufgetreten. \n\nKontaktiere den Support mit folgender Meldung\n" +
                  error
              );
              console.error(error);
            });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
