<template>
  <div>
    <NavigationBar
      titel="Ressourcenplaner"
      isPreview
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-snackbar v-model="snackbar.model" timeout="3000">{{
        snackbar.text
      }}</v-snackbar>
    </section>
    <section v-if="mobile">
      <v-container class="fill-height mt-16">
        <v-row align="top" justify="center">
          <v-col cols="12" sm="12" md="8">
            <v-card>
              <v-card-title class="d-flex justify-center"
                ><div class="text-center">
                  <v-avatar color="error" rounded size="70" class="mb-4">
                    <v-icon x-large color="white"> mdi-cellphone-off</v-icon>
                  </v-avatar>
                  <div>Bildschirm zu klein für diese Funktion</div>
                </div>
              </v-card-title>
              <v-card-subtitle class="text-center"
                >Diese Ansicht steht nur auf größeren Bildschirmen zur
                Verfügung.</v-card-subtitle
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else>
      <template>
        <v-navigation-drawer
          app
          bottom
          clipped
          width="350px"
          height="100vh"
          permanent
          touchless
        >
          <div class="mx-3 my-2">
            <v-text-field
              v-model="membershipsSearch"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              label="Nach Personen suchen"
              clearable
              hide-details="auto"
            ></v-text-field>
            <!-- <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" class="mt-2">
                  <span>
                    Gruppieren nach <strong>{{ groupBy }}</strong>
                  </span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list nav>
                <v-list-item-group v-model="groupBy" mandatory>
                  <v-list-item
                    v-for="(item, index) in groupItems"
                    :key="index"
                    dense
                    :value="item.value"
                  >
                    <v-list-item-icon>
                      <v-icon dense>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu> -->
          </div>

          <v-divider class="mt-3"></v-divider>
          <v-data-table
            :items="members"
            :headers="headers"
            :search="membershipsSearch"
            :group-by="groupBy"
            :group-desc="false"
            :sort-desc="false"
            sort-by="user.displayName"
            dense
            fixed-header
            disable-pagination
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template v-slot:[`header.workload`]="{}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"
                    >mdi-briefcase-upload-outline</v-icon
                  >
                </template>
                <span>Übernommener Aufwand</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.count`]="{}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-sigma</v-icon>
                </template>
                <span>Summe an Zuweisungen</span>
              </v-tooltip>
            </template>
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
              <th :colspan="headers.length" class="group-header-th">
                <v-icon @click="toggle" class="mr-2"
                  >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                <span>
                  {{ items[0].group.title }}
                </span>
              </th>
            </template>
            <template v-slot:[`item.workload`]="{ item }">
              <assignment-status-chip
                showCount
                hideInfoText
                :count="item.workloadScore"
                :minThreshold="assignmentsConfig.thresholds.minWorkloadCount"
                :maxThreshold="assignmentsConfig.thresholds.maxWorkloadCount"
                small
              ></assignment-status-chip>
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <assignment-status-chip
                showCount
                hideInfoText
                :count="item.assignmentCount"
                :minThreshold="assignmentsConfig.thresholds.minAssignmentsCount"
                :maxThreshold="assignmentsConfig.thresholds.maxAssignmentsCount"
                small
              ></assignment-status-chip>
            </template>
            <template v-slot:top>
              <v-btn
                depressed
                class="ml-3 my-2"
                small
                @click="toggleThresholdsDialog"
                ><v-icon left dense>mdi-counter</v-icon>Schwellenwerte
                anpassen</v-btn
              >
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </v-navigation-drawer>
        <div class="overflow-x-auto">
          <v-container>
            <v-row justify="center" align="center">
              <v-col cols="12">
                <div class="mt-7">
                  <v-data-iterator
                    :items="assignments"
                    item-key="meta.id"
                    sort-by="title"
                    no-data-text="Keine Aufgabenbereiche vorhanden."
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:default="props">
                      <v-row class="flex-nowrap">
                        <v-col
                          v-for="assignment in props.items"
                          :key="assignment.meta.id"
                          cols="12"
                          md="5"
                          sm="8"
                        >
                          <v-card height="500px">
                            <v-card-title
                              >{{ assignment.title }} <v-spacer></v-spacer
                              ><assignment-status-chip
                                :count="assignment.assignees.length"
                                :minThreshold="assignment.analysis.minAssignees"
                                :maxThreshold="assignment.analysis.maxAssignees"
                                small
                              ></assignment-status-chip
                            ></v-card-title>
                            <v-card-subtitle>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip label small v-bind="attrs" v-on="on">
                                    <v-icon x-small left
                                      >mdi-briefcase-upload-outline</v-icon
                                    >
                                    <span>{{ assignment.workloadScore }}</span>
                                  </v-chip>
                                </template>
                                <span>Aufwandswert</span>
                              </v-tooltip>
                              <v-chip
                                label
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="mx-2"
                              >
                                <v-icon small left>mdi-dumbbell</v-icon>
                                <span>{{ assignment.priorityScore }}</span>
                              </v-chip>
                              Min.:
                              {{ assignment.analysis.minAssignees }}; Max.:
                              {{ assignment.analysis.maxAssignees }}
                            </v-card-subtitle>
                            <v-card-text
                              v-if="checkForDuplicates(assignment.assignees)"
                              class="mb-0"
                            >
                              <v-alert dense text type="error" class="mb-0">
                                Fehler: Doppelzuweisungen nicht
                                zulässig</v-alert
                              >
                            </v-card-text>
                            <span class="overflow-y-auto">
                              <v-card-text>
                                <v-card
                                  v-for="assignee in assignment.assignees"
                                  :key="assignee.user.uid"
                                  outlined
                                  class="my-1"
                                >
                                  <v-card-title
                                    class="my-n4 mb-1 mx-n1 subtitle-1 font-weight-medium"
                                    >{{ assignee.user.displayName }}
                                    <v-icon
                                      v-if="assignee.isLeader"
                                      color="warning"
                                      class="ml-1"
                                      x-small
                                      >mdi-star</v-icon
                                    >
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn icon small
                                      ><v-icon small>mdi-pencil</v-icon></v-btn
                                    > -->
                                    <v-btn
                                      icon
                                      small
                                      @click="
                                        deleteAssignee(assignee, assignment)
                                      "
                                      ><v-icon small>mdi-close</v-icon></v-btn
                                    >
                                  </v-card-title>
                                  <v-card-subtitle class="mb-n4 mt-n5 mx-n1">{{
                                    assignee.position
                                  }}</v-card-subtitle>
                                  <div
                                    v-if="!assignee.position"
                                    class="mb-1"
                                  ></div>
                                </v-card>
                              </v-card-text>
                              <v-card-title class="font-weight-normal mt-n5"
                                ><v-autocomplete
                                  v-model="assignment.newAssignee"
                                  :items="membershipsForAssigneeSelection"
                                  outlined
                                  label="Person hinzufügen"
                                  dense
                                  return-object
                                  hide-details="auto"
                                  item-text="displayName"
                                  item-value="uid"
                                ></v-autocomplete>
                                <v-btn
                                  depressed
                                  dense
                                  class="ml-2"
                                  @click="addAssignee(assignment)"
                                  ><v-icon>mdi-plus</v-icon></v-btn
                                ></v-card-title
                              >
                            </span>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.thresholds.dialogModel"
      @close="toggleThresholdsDialog()"
      title="Schwellenwerte anpassen"
      text="Anhand der festgelegten Schwellenwerte werden für alle Mitglieder entsprechende Statusanzeigen (Optimal = Grün, Defizit = Orange, Überhang = Rot) berechnet."
      width="s"
      :errorMessage="helpers.thresholds.errorMessage"
      :btnAction="updateThresholds"
      :btnDisabled="!helpers.thresholds.formIsValid"
      :loading="helpers.thresholds.loading"
      btnText="Speichern"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.thresholds.formIsValid"
          @submit.prevent="updateThresholds()"
        >
          <v-card outlined class="mt-2">
            <v-card-subtitle class="font-weight-medium"
              ><v-icon dense class="mr-2">mdi-briefcase-upload-outline</v-icon
              >Übernommener Aufwand</v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="
                      assignmentsConfig.thresholds.minWorkloadCount
                    "
                    label="Minimum"
                    dense
                    hide-details="auto"
                    :rules="[rules.required]"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="
                      assignmentsConfig.thresholds.maxWorkloadCount
                    "
                    label="Maximum"
                    dense
                    hide-details="auto"
                    :rules="[rules.required]"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-subtitle class="font-weight-medium"
              ><v-icon dense class="mr-2">mdi-sigma</v-icon>Zugewiesene
              Aufgabenbereiche</v-card-subtitle
            ><v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="
                      assignmentsConfig.thresholds.minAssignmentsCount
                    "
                    label="Minimum"
                    dense
                    hide-details="auto"
                    :rules="[rules.required]"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="
                      assignmentsConfig.thresholds.maxAssignmentsCount
                    "
                    label="Maximum"
                    dense
                    hide-details="auto"
                    :rules="[rules.required]"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import AssignmentStatusChip from "@/components/member-management/assignments/AssignmentStatusChip.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { MEMBER_MANAGEMENT, ADMIN } from "@/store/modules.js";
import {
  GET_ASSIGNMENTS,
  GET_USER_LIST,
  UPDATE_ASSIGNMENTS,
  UPDATE_MEMBER_MANAGEMENT_CONFIG,
} from "@/store/action-types.js";

export default {
  name: "member-management-assignments-planner",
  components: {
    NavigationBar,
    AssignmentStatusChip,
    CustomDialog,
  },
  data() {
    return {
      groupBy: "group.title",
      membershipsSearch: "",
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      snackbar: {
        model: false,
        text: "",
      },
      helpers: {
        thresholds: {
          dialogModel: false,
          formIsValid: false,
          loading: false,
          errorMessage: "",
        },
        formIsValid: false,
      },

      headers: [
        { text: "Name", value: "user.displayName", divider: true },
        { text: "Aufwand", value: "workload" },
        { text: "Zuweisungen", value: "count" },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    actions() {
      if (!this.mobile) {
        return [
          {
            title: "Speichern",
            icon: "mdi-content-save",
            actionStyle: "textBtnSubmit",
            function: this.updateAssignments,
          },
        ];
      } else {
        return [];
      }
    },
    assignmentsConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement.assignments;
    },
    assignments() {
      return this.$store.state.memberManagement.assignments;
    },
    members() {
      const input = this.$store.state.admin.membershipsPublic;
      const result = input.map((content) => ({
        ...content,
        searchableDisplayName: content.user.displayName,
        workloadScore: this.getWorkloadScoreByUid(content.user.uid),
        assignmentCount: this.getAssignmentCountByUid(content.user.uid),
      }));
      return result;
    },
    membershipsForAssigneeSelection() {
      var result = this.$store.state.admin.membershipsPublic.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
    noDuplicateAssignees() {
      return this.assignments.some((assignment) => {
        return this.checkForDuplicates(assignment.assignees);
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    checkForDuplicates(assignees) {
      const assigneesUids = assignees.map((item) => item.user.uid);
      const hasDuplicates = (array) => new Set(array).size !== array.length;
      return hasDuplicates(assigneesUids);
    },
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_ASSIGNMENTS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    toggleThresholdsDialog() {
      this.helpers.thresholds.dialogModel =
        !this.helpers.thresholds.dialogModel;
    },
    updateThresholds() {
      this.helpers.thresholds.loading = true;
      if (this.helpers.thresholds.formIsValid) {
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${UPDATE_MEMBER_MANAGEMENT_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlyAssignmentsThresholdsConfig",

            thresholds: {
              minWorkloadCount:
                this.assignmentsConfig.thresholds.minWorkloadCount,
              maxWorkloadCount:
                this.assignmentsConfig.thresholds.maxWorkloadCount,
              minAssignmentsCount:
                this.assignmentsConfig.thresholds.minAssignmentsCount,
              maxAssignmentsCount:
                this.assignmentsConfig.thresholds.maxAssignmentsCount,
            },
          })
          .then(() => {
            this.helpers.thresholds.loading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Schwellenwerte erfolgreich aktualisiert.";
            this.toggleThresholdsDialog();
          })
          .catch((error) => {
            console.log(error);
            this.helpers.thresholds.loading = error.message;
            this.helpers.thresholds.loading = false;
          });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    getWorkloadScoreByUid(uid) {
      const assignmentsWithWorkload = this.assignments.map((assignment) => {
        return {
          assignees: assignment.assignees.map((assignee) => {
            return {
              ...assignee,
              workloadScore: assignment.workloadScore,
            };
          }),
        };
      });
      const allAssignments = assignmentsWithWorkload
        .map((assignment) => assignment.assignees)
        .flat();

      const assignmentsOfUser = allAssignments.filter(
        (item) => item.user.uid === uid
      );
      // Sum up workloadScore of all assignments of user
      const workloadScore = assignmentsOfUser.reduce(
        (a, b) => a + b.workloadScore,
        0
      );
      return workloadScore;
    },
    getAssignmentCountByUid(uid) {
      const allAssignments = this.assignments
        .map((assignment) => assignment.assignees)
        .flat();
      return allAssignments.filter((item) => item.user.uid === uid).length;
    },
    addAssignee(assignment) {
      if (assignment.newAssignee) {
        assignment.assignees.push({
          user: {
            displayName: assignment.newAssignee.displayName,
            uid: assignment.newAssignee.uid,
          },
          dutyPosition: "",
          isLeader: false,
        });
        assignment.newAssignee = null;
      }
    },
    deleteAssignee(assignee, assignment) {
      assignment.assignees.splice(assignment.assignees.indexOf(assignee), 1);
    },
    updateAssignments() {
      if (this.noDuplicateAssignees) {
        alert(
          "Fehler: Mitglieder dürfen einem Aufgabengebiet nicht mehrfach zugeordnet werden."
        );
      } else {
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${UPDATE_ASSIGNMENTS}`, {
            organizationId: this.$route.params.organizationId,

            assignments: this.assignments,
          })
          .catch((error) => {
            alert(
              "Es ist ein Fehler aufgetreten. \n\nKontaktiere den Support mit folgender Meldung\n" +
                error
            );
            console.error(error);
          });
      }
    },
  },
};
</script>
