<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <assignment-detail-card
                  usedInDetailView
                  :assignment="assignment"
                ></assignment-detail-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { DELETE_ASSIGNMENT } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import AssignmentDetailCard from "@/components/member-management/assignments/AssignmentDetailCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  MY_ORGANIZATION_TEAMS_CATEGORIES_update,
  MY_ORGANIZATION_TEAMS_CATEGORIES_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-assignments-assignment-details",
  components: {
    NavigationBar,
    Subheader,
    AssignmentDetailCard,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MY_ORGANIZATION_TEAMS_CATEGORIES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${MY_ORGANIZATION_TEAMS_CATEGORIES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      assignment: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "assignment", data: this.assignment }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("assignments")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.assignment = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    editItem() {
      this.$router.push({
        name: "member-management-assignments-assignment-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du das Aufgabengebiet löschen möchtest?\n\nEs werden alle Zuweisungen für diese Aufgabe entfernt. In der Folge können sich Änderungen im Ressourcenplanner ergeben."
      );
      if (res == true) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${DELETE_ASSIGNMENT}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
  },
};
</script>

<style></style>
