<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="assignmentLocal.title"
                          label="Titel des Aufgabengebiets"
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="assignmentLocal.isHidden"
                          outlined
                          persistent-hint
                          label="Aufgabengebiet in Organisationsübersicht ausblenden"
                        ></v-checkbox
                      ></v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="assignmentLocal.description"
                          counter
                          clearable
                          no-resize
                          label="Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="match-height">
                      <v-col cols="12" md="6">
                        <v-card outlined>
                          <v-card-text>
                            Icon auswählen
                            <data-avatar
                              :icon="assignmentLocal.icon"
                              :color="assignmentLocal.color"
                            ></data-avatar>
                            <v-text-field
                              v-model="assignmentLocal.icon"
                              label="Icon ID"
                              persistent-hint
                              required
                              prefix="mdi-"
                              :rules="[rules.required]"
                              outlined
                              hint="ID von Icon aus Material Design Icons Bibiliothek angeben"
                              prepend-inner-icon="mdi-emoticon"
                              class="mt-4"
                            >
                              <template v-slot:append-outer
                                ><v-btn
                                  depressed
                                  small
                                  @click="openMdiIconLibrary"
                                  >Icons durchsuchen
                                  <v-icon right>mdi-open-in-new</v-icon></v-btn
                                ></template
                              ></v-text-field
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card outlined>
                          <v-card-text>
                            Farbe auswählen
                            <v-color-picker
                              v-model="assignmentLocal.color"
                              :swatches="availableColors"
                              mode="hexa"
                              hide-inputs
                              hide-canvas
                              hide-sliders
                              show-swatches
                              dot-size="2"
                              width="1200"
                              swatches-max-height="150"
                              class="ma-0 pa-0"
                            ></v-color-picker>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12">
            <Subheader
              icon="mdi-badge-account-horizontal"
              title="Kontaktmöglichkeiten"
            ></Subheader>
            <div class="mt-7">
              <template
                v-for="(email, index) in assignmentLocal.contact.emails"
              >
                <v-card class="mb-3" :key="index">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="email.title"
                            outlined
                            label="Bezeichnung"
                            hint="Diese Bezeichnung wird zusammen mit der E-Mail-Adresse angezeigt."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="email.adress"
                            outlined
                            label="E-Mail-Adresse"
                            :rules="[rules.required, rules.email]"
                            prepend-inner-icon="mdi-email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="deleteEmail(email)">
                      <v-icon left>mdi-delete</v-icon>
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>

              <v-col cols="12" align="center">
                <v-btn
                  text
                  color="success"
                  :disabled="assignmentLocal.contact.emails.length > 2"
                  @click="addEmail()"
                >
                  <v-icon left>mdi-email-plus</v-icon>E-Mail-Adresse hinzufügen
                </v-btn>
              </v-col>
            </div>
            <div class="mt-7">
              <template v-for="(link, index) in assignmentLocal.contact.links">
                <v-card class="mb-3" :key="index">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="link.title"
                            outlined
                            label="Bezeichnung"
                            hint="Diese Bezeichnung wird zusammen mit dem Link angezeigt."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="link.url"
                            outlined
                            label="Link"
                            :rules="[rules.required, rules.link]"
                            prepend-inner-icon="mdi-link"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="deleteLink(link)">
                      <v-icon left>mdi-delete</v-icon>
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
              <v-col cols="12" align="center">
                <v-btn
                  text
                  color="success"
                  class="ml-2"
                  :disabled="assignmentLocal.contact.links.length > 2"
                  @click="addLink()"
                >
                  <v-icon left>mdi-link-plus</v-icon>Link hinzufügen
                </v-btn>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12">
            <Subheader
              icon="mdi-chart-bar-stacked"
              title="Kennzahlen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model.number="assignmentLocal.priorityScore"
                          :items="priorityItems"
                          outlined
                          persistent-hint
                          single-line
                          hint="Diese Wert unterstützt bei der Aufgabenverteilung im Ressourcenplaner."
                          label="Priorität"
                          prepend-inner-icon="mdi-dumbbell"
                          :rules="[rules.required]"
                        ></v-select
                      ></v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.number="assignmentLocal.workloadScore"
                          outlined
                          type="number"
                          label="Aufwandswert"
                          hint="Der Aufwandswert dient der Berechnung des geleisteten Aufwands durch Summierung bei mehreren übernommenen Aufgaben. Ein höher Wert bedeutet eine aufwändigere und zeitintensivere Aufgabe."
                          persistent-hint
                          :rules="[rules.required]"
                          prepend-inner-icon="mdi-briefcase-upload-outline"
                        ></v-text-field></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.number="assignmentLocal.analysis.minAssignees"
                          outlined
                          type="number"
                          label="Min. Zuweisungen"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.number="assignmentLocal.analysis.maxAssignees"
                          outlined
                          type="number"
                          label="Max. Zuweisungen"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="assignmentLocal.analysis.isShown"
                          outlined
                          persistent-hint
                          label="Kennzahlen in Organisationsübersicht anzeigen"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12">
            <Subheader icon="mdi-account-group" title="Zuweisungen"></Subheader>
            <div class="mt-7">
              <v-row v-if="mode === 'new'">
                <v-col cols="12">
                  <v-alert
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    Du musst den Aufgabenbereich zunächst anlegen, um Personen
                    zuzuweisen.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-else>
                <template v-for="assignee in assignmentLocal.assignees">
                  <v-col cols="12" md="4" :key="assignee.user.uid">
                    <v-card>
                      <v-card-text>
                        <v-autocomplete
                          v-model="assignee.user"
                          :items="memberships"
                          outlined
                          persistent-hint
                          return-object
                          label="Person hinzufügen"
                          item-text="displayName"
                          item-value="uid"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-text-field
                          v-if="!!assignee.user.uid"
                          v-model="assignee.position"
                          outlined
                          label="Position im Aufgabengebiet"
                          hint="Wird zusammen mit dem Namen angezeigt."
                          persistent-hint
                        ></v-text-field>
                        <v-checkbox
                          v-if="!!assignee.user.uid"
                          v-model="assignee.isLeader"
                          label="Leitung des Aufgabengebiets"
                          hint="Die Position wird entsprechend gekennzeichnet."
                          persistent-hint
                        ></v-checkbox>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions
                        ><v-spacer></v-spacer
                        ><v-btn
                          text
                          color="error"
                          @click="deleteAssignee(assignee)"
                        >
                          <v-icon dense left>mdi-delete</v-icon>Löschen</v-btn
                        ></v-card-actions
                      >
                    </v-card>
                  </v-col>
                </template>
                <v-col cols="12" md="4">
                  <v-btn text color="success" @click="addAssignee()">
                    <v-icon left>mdi-account-plus</v-icon>Aufgabengebiet Person
                    zuweisen
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";

export default {
  name: "assignment-editor",
  props: {
    assignment: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    DataAvatar,
  },
  data() {
    return {
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        link: (value) =>
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            value
          ) || "Ungültiger Link",
      },

      priorityItems: [
        { value: 1, text: "Sehr hohe Priorität" },
        { value: 2, text: "Hohe Priorität" },
        { value: 3, text: "Mittlere Priorität" },
        { value: 4, text: "Geringe Priorität" },
        { value: 5, text: "Sehr geringe Priorität" },
      ],
    };
  },
  model: {
    prop: "assignment",
    event: "assignmentChange",
  },
  computed: {
    assignmentLocal: {
      get: function () {
        return this.assignment;
      },
      set: function (value) {
        this.$emit("assignmentChange", value);
      },
    },
    memberships() {
      var result = this.$store.state.admin.membershipsPublic.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
    addLink() {
      if (this.assignmentLocal.contact.links.length < 3) {
        this.assignmentLocal.contact.links.push({
          title: "",
          link: "",
        });
      } else {
        alert("Fehler: Es können nicht mehr als drei Links hinterlegt werden.");
      }
    },
    deleteLink(link) {
      this.assignmentLocal.contact.links.splice(
        this.assignmentLocal.contact.links.indexOf(link),
        1
      );
    },
    addEmail() {
      if (this.assignmentLocal.contact.emails.length < 3) {
        this.assignmentLocal.contact.emails.push({
          title: "",
          adress: "",
        });
      } else {
        alert(
          "Fehler: Es können nicht mehr als drei E-Mail-Adressen hinterlegt werden."
        );
      }
    },
    deleteEmail(email) {
      this.assignmentLocal.contact.emails.splice(
        this.assignmentLocal.contact.emails.indexOf(email),
        1
      );
    },
    addAssignee() {
      this.assignmentLocal.assignees.push({
        user: { displayName: "", uid: "" },
        position: "",
        isLeader: false,
      });
    },
    deleteAssignee(assignee) {
      this.assignmentLocal.assignees.splice(
        this.assignmentLocal.assignees.indexOf(assignee),
        1
      );
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
