var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_c('data-avatar',{attrs:{"icon":_vm.assignment.icon,"color":_vm.assignment.color}}),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.assignment.title))]),_c('v-spacer'),(_vm.usedInDetailView || _vm.assignment.analysis.isShown)?_c('v-chip',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"label":""}},'v-chip',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-dumbbell")]),_c('span',[_vm._v(_vm._s(_vm.getPriorityText(_vm.assignment.priorityScore)))])],1):_vm._e(),(_vm.usedInDetailView || _vm.assignment.analysis.isShown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-briefcase-upload-outline")]),_c('span',[_vm._v(_vm._s(_vm.assignment.workloadScore))])],1)]}}],null,false,3836578779)},[_c('span',[_vm._v("Aufwandswert")])]):_vm._e(),(_vm.usedInDetailView || _vm.assignment.analysis.isShown)?_c('assignment-status-chip',{attrs:{"count":_vm.assignment.assignees.length,"minThreshold":_vm.assignment.analysis.minAssignees,"maxThreshold":_vm.assignment.analysis.maxAssignees}}):_vm._e()],1),_c('v-container',[_c('v-row',{},[_c('v-col',{attrs:{"cols":"12","md":_vm.assignment.contact.emails.length > 0 ||
            _vm.assignment.contact.links.length > 0
              ? '8'
              : '12'}},[(_vm.assignment.description)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"subtitle-2"},[_vm._v("Tätigkeitsbeschreibung")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.assignment.description))])],1):_vm._e(),_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"subtitle-2"},[_vm._v("Zugewiesen")]),_c('v-data-iterator',{attrs:{"items":_vm.assignment.assignees,"sort-by":"isLeader","sort-desc":"","hide-default-footer":"","no-data-text":"Diesem Aufgabengebiet sind keine Mitglieder zugewiesen."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0"},[_vm._v("Diesem Aufgabengebiet sind keine Mitglieder zugewiesen.")])],1)]},proxy:true},{key:"default",fn:function(props){return [_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',_vm._l((props.items),function(assignee){return _c('v-col',{key:assignee.user.uid,attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(_vm._s(assignee.user.displayName)+" "),_c('v-spacer'),(assignee.isLeader)?_c('v-chip',{attrs:{"color":"warning","label":"","small":""}},[_vm._v("Leitung")]):_vm._e()],1),_c('v-card-subtitle',[_vm._v(_vm._s(assignee.position))])],1)],1)}),1)],1)]}}])})],1)],1),(
            _vm.assignment.contact.emails.length > 0 ||
            _vm.assignment.contact.links.length > 0
          )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"subtitle-2"},[_vm._v("Kontakt")]),_c('v-list',[_vm._l((_vm.assignment.contact.emails),function(email,index){return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-email ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":`mailto:${email.adress}`}},[_vm._v(_vm._s(email.adress)+" ")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(email.title))])],1)],1)}),_vm._l((_vm.assignment.contact.links),function(link,index){return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-link ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":link.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.getHostname(link.url)))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(link.title))])],1)],1)})],2)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }