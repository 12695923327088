<template>
  <div>
    <v-avatar
      :color="`${getMaterialColor(color)} lighten-5`"
      :rounded="rounded"
      :size="size ? size : small ? 34 : 48"
    >
      <v-icon :color="color" :small="small"> mdi-{{ icon }}</v-icon>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "data-avatar",
  props: {
    icon: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
          return "cyan";
        case "#009688FF":
        case "#009688":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
