<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Neuen Aufgabenbereich erstellen"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <assignment-editor v-model="assignment" mode="new"></assignment-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import AssignmentEditor from "@/components/member-management/assignments/AssignmentEditor.vue";
import { MEMBER_MANAGEMENT_ASSIGNMENTS_create } from "@/data/permission-types.js";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_USER_LIST, CREATE_ASSIGNMENT } from "@/store/action-types.js";

export default {
  name: "member-management-assignments-assignment-new",
  components: {
    NavigationBar,
    AssignmentEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      assignment: {
        title: "",
        description: "",
        isHidden: false,
        contact: {
          emails: [],
          links: [],
        },
        color: "#F44336FF",
        icon: "fire-truck",
        priorityScore: 1,
        workloadScore: 0,
        assignees: [],
        analysis: {
          minAssignees: 0,
          maxAssignees: 0,
          currentAssignees: 0,
          currentLeaders: 0,
          isShown: false,
        },
      },

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    memberships() {
      var result = this.$store.state.admin.benutzer.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_ASSIGNMENT}`, {
            organizationId: this.$route.params.organizationId,

            title: this.assignment.title,
            description: this.assignment.description,
            isHidden: this.assignment.isHidden,
            contact: {
              emails: this.assignment.contact.emails,
              links: this.assignment.contact.links,
            },
            color: this.assignment.color,
            icon: this.assignment.icon,
            priorityScore: this.assignment.priorityScore,
            workloadScore: this.assignment.workloadScore,
            assignees: this.assignment.assignees,
            analysis: {
              minAssignees: this.assignment.analysis.minAssignees,
              maxAssignees: this.assignment.analysis.maxAssignees,
              currentAssignees: this.assignment.analysis.currentAssignees,
              currentLeaders: this.assignment.analysis.currentLeaders,
              isShown: this.assignment.analysis.isShown,
            },
          })
          .catch(function (error) {
            alert(
              "Es ist ein Fehler aufgetreten. \n\nKontaktiere den Support mit folgender Meldung\n" +
                error
            );
            console.error(error);
          });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
