<template>
  <div>
    <v-chip
      :color="statusColor()"
      label
      :small="small"
      class="font-weight-medium text--darken-4"
      :class="textClass"
      ><v-icon left small>{{ statusIcon() }}</v-icon
      >{{ statusText() }}</v-chip
    >
  </div>
</template>

<script>
export default {
  name: "assignment-status-button",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideInfoText: {
      type: Boolean,
      required: false,
      default: false,
    },
    count: {
      type: Number,
      required: true,
    },
    minThreshold: {
      type: Number,
      required: true,
    },
    maxThreshold: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      textClass: "",
    };
  },
  created() {
    this.statusStyle();
  },
  computed: {
    statusValue() {
      if (this.count < this.minThreshold) {
        return "deficit";
      } else if (
        this.count >= this.minThreshold &&
        this.count <= this.maxThreshold
      ) {
        return "target";
      } else if (this.count > this.maxThreshold) {
        return "overhang";
      } else {
        return "";
      }
    },
  },
  methods: {
    statusText() {
      var additionalInfoCount;
      switch (this.statusValue) {
        case "deficit":
          additionalInfoCount = this.count - this.minThreshold;
          return (
            (this.hideInfoText ? "" : "Defizit") +
            (this.showCount ? " " + " " + this.count : "") +
            " (" +
            additionalInfoCount +
            ")"
          );

        case "target":
          return (
            (this.hideInfoText ? "" : "Optimal") +
            (this.showCount ? " " + " " + this.count : "")
          );
        case "overhang":
          additionalInfoCount = this.count - this.maxThreshold;
          return (
            (this.hideInfoText ? "" : "Überhang") +
            (this.showCount ? " " + " " + this.count : "") +
            " (+" +
            additionalInfoCount +
            ")"
          );
        default:
          "";
      }
    },
    statusColor() {
      switch (this.statusValue) {
        case "deficit":
          return "red lighten-4";
        case "target":
          return "green lighten-4";
        case "overhang":
          return "orange lighten-4";
        default:
          "";
      }
    },
    statusIcon() {
      switch (this.statusValue) {
        case "deficit":
          return "mdi-arrow-down-circle";
        case "target":
          return "mdi-check-circle";
        case "overhang":
          return "mdi-arrow-up-circle";
        default:
          "";
      }
    },
    statusStyle() {
      switch (this.statusValue) {
        case "deficit":
          return (this.textClass = "red--text");
        case "target":
          return (this.textClass = "green--text");
        case "overhang":
          return (this.textClass = "orange--text");
        default:
          "";
      }
    },
  },
};
</script>
